import React from "react";

const ContactMap = () => {
  return (
    <div className="map_section">
      <div id="map">
        <iframe
          title="template google map"
          src="https://maps.google.com/maps?q=estoril%20office%20center&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap;
