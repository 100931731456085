import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Logo, NavLinksData, TopbarInfos, FooterBottomData } from "@/data";

const ContactThree = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSuccess(true);

    emailjs
      .sendForm('service_hjqzc87', 'template_clemtx2', form.current, {
        publicKey: 'HC2je1M_WgxRsMcHY',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <section className="contact_form_area contact_us section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-right contact_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">Entre em Contacto</h4>
                <h1>Visitas para orçamentos gratuitas 6 dias por semana</h1>
              </div>
              <p>

              </p>
              <div className="contact_location_box">
                <div className="contact_location_map">
                <div className="header_contact">
            <ul className="hd_contact">
              {TopbarInfos.map(({ icon, tagLine, text, url }, index) => (
                <li key={`header-info-${index}`}>
                  <i className={icon}></i> {tagLine}
                  <a href={url}> {text}</a>
                </li>
              ))}
            </ul>
          </div>
                </div>

              </div>
            </div>
          </div>
          <div className="col-md-6">
            {/* <div className="contact-right contact-right-style-2 responsive_mt">
              <form name="contact" method="post" data-netlify="true" onSubmit="submit" data-netlify-honeypot="bot-field">
                <input hidden name="bot-field"></input>
                <input type="hidden" name="form-name" value="contact"/>
                <input
                  className="half_width input_m_right"
                  type="text"
                  name="name"
                  placeholder="Nome"
                />
                <input
                  className="half_width"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
                <input type="tel" name="tel" placeholder="Telemóvel" />
                <textarea
                  name="content"
                  id="content"
                  cols="20"
                  rows="5"
                  placeholder="A sua Mensagem"
                ></textarea>
                <button className="btn-yellow" type="submit">
                  Enviar
                </button>
              </form>
            </div> */}
            <div className="contact-right contact-right-style-2 responsive_mt">
              {!isSuccess && (<form ref={form} onSubmit={sendEmail}>
                <input
                    className="half_width input_m_right"
                    type="text"
                    name="name"
                    placeholder="Nome"
                  />
                  <input
                    className="half_width"
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                  <input type="tel" name="tel" placeholder="Telemóvel" />
                  <textarea
                    name="content"
                    id="content"
                    cols="20"
                    rows="5"
                    placeholder="A sua Mensagem"
                  ></textarea>
                  <input className="btn-yellow" type="submit" value="Send" />
              </form>)}

              {isSuccess && <p>Mensagem enviada com sucesso!</p>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactThree;
